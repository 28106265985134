import React, { Component , useState} from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../banner.png';
import Banner_m from '../banner-mobile.jpg';
import LOGO from '../binance.png';

class MoonBix extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isRotated: false,
        };
        this.handleClick = this.handleClick.bind(this);
    }
    
    handleClick() {   
        this.setState((prevState) => ({
            isRotated: !prevState.isRotated,
        }));
    }


    componentDidMount() {
        
    }
    
    //@Binance_Moonbixa_Bot
    //https://t.me/Binance_Moonbix_bot/start?startApp=ref_1992483940&startapp=ref_1992483940
    //https://t.me/Binance_Moonbixa_Bot
    //https://t.me/binance_moonbix_airdrop_ann

    render() {
        return <main class="ln">
                <div className="w-full  bg-main text-white text-center ">
                        <img src={LOGO} className='mx-auto w-16 mt-10' />
                        <div className="text-5xl mt-4">MOONBIX GAME</div>
                        <div className="ln-content">
                            <div className="text-2xl mt-4 ln-content-button">
                                <a href="https://t.me/Binance_Moonbix_bot/start?startApp=ref_1992483940&startapp=ref_1992483940" className='button'>
                                    <span>PLAY TO EARN</span>
                                </a>
                            </div>
                        </div>
                        <p>Binance Launches Project MOONBIX on Telegram: Play and Earn</p>
                        
                        <div className="text-center mt-10">
                                <img src={Banner_m} className='mx-auto ln-content' />
                        </div>
                        
                 </div>

                

            </main>
    }
}

export default MoonBix;