import logo from './logo.svg';
import './App.css';
import React , {Component } from 'react';
import MoonBix from './website/MoonBix';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentToRender: null,
    };
  }

  componentDidMount() {
    const currentUrl = window.location.href;

    console.log(currentUrl);
    if (currentUrl.includes('moonbixgame.xyz')) {
      this.setState({ componentToRender: <MoonBix /> });
    } 

    if (currentUrl.includes('moonbix.online')) {
      this.setState({ componentToRender: <MoonBix /> });
    } 
    
    this.setState({ componentToRender: <MoonBix /> });
  }

  render() {
    const { componentToRender } = this.state;
    return (
      <div>{componentToRender}</div>
    );
  }
}

export default App;
